export const templates = [{
        name: "Australasia",
        enabled: true,
        openonload: true,
        description: [
            "Arup appreciates you speaking up in relation to making a disclosure about perceived illegal conduct or unethical behaviour that you believe may involve Arup and/or its employees or suppliers of goods and services to Arup. Please answer a few short questions about your disclosure. Your answers to these questions will help us direct your disclosure to the appropriate person from our Speak Up team.",
            "Your disclosure will be kept strictly confidential, and any information you disclose online will be kept in a secure file management system. Arup will assess all disclosures made, and will determine what action, including the requirement for an investigation, is required. If you elect to provide your contact details you will be kept informed, where appropriate of any actions that Arup have taken regarding your disclosure.",
        ],
        formElements: [{
                field: "disclosureNature",
                label: "What is the illegal conduct or unethical behaviour you are disclosing?",
                required: true,
                helpText: [
                    "Please describe the illegal conduct or unethical behaviour you are disclosing. Types of conduct you may want to disclose include, but are not limited to, dishonesty, fraud, corruption and bribery. If you would like, a Speak Up team member can contact you to discuss your disclosure.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "8",
            },
            {
                field: "disclosurePeople",
                label: "Who is/are the person/people involved in this disclosure?",
                required: true,
                helpText: [
                    "If known, list the people, teams or broader groups that are doing the illegal conduct or unethical behaviour.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "incidentDate",
                label: "What date/dates did the incident take place?",
                required: false,
                helpText: [
                    'If known, provide a date or time span when the illegal conduct or unethical behaviour happened e.g. "1 January 2020" or from "January to March 2019".',
                ],
                isMultiline: true,
                multilineRows: "5",
                isColumn: true,
            },
            [{
                    field: "name",
                    label: "Name",
                    required: false,
                    helpText: [],
                    isMultiline: false,
                    multilineRows: "1",
                    isColumn: false,
                    row: "row_1",
                    spacing: 3,
                    optionalLabel: true,
                },
                {
                    field: "email",
                    label: "Email Address",
                    required: false,
                    helpText: [],
                    isColumn: false,
                    isMultiline: false,
                    multilineRows: "1",
                    row: "row_1",
                    spacing: 4,
                    optionalLabel: true,
                },
            ],
        ],
    },
    {
        name: "Americas",
        enabled: true,
        openonload: true,
        description: [
            "Arup appreciates you speaking up in relation to making a disclosure about perceived illegal conduct or unethical behavior that you believe may involve Arup and/or its employees. Please answer a few short questions about your disclosure. Your answers to these questions will help us direct your disclosure to the appropriate person from our Speak Up team.",
            "Your disclosure will be kept confidential, and any information you disclose here will be kept in a secure file management system. Arup will assess all disclosures made, and will determine what action, including the requirement for an investigation, is required. If you elect to provide your contact details you will be kept informed, where appropriate of any actions that Arup have taken regarding your disclosure.",
        ],
        formElements: [{
                field: "disclosureNature",
                label: "What is the illegal conduct or unethical behavior you are disclosing?",
                required: true,
                helpText: [
                    "Please describe the illegal conduct or unethical behavior you are disclosing. Types of conduct you may want to disclose include dishonesty, fraud, corruption, and bribery.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "8",
            },
            {
                field: "howAwareConduct",
                label: "How did you become aware of this conduct?",
                required: false,
                helpText: [
                    "Please describe how you became aware of this conduct, whether you witnessed it firsthand or were informed by someone else.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "disclosurePeople",
                label: "Who is/are the person/people involved in this disclosure?",
                required: true,
                helpText: [
                    "If known, list the people, teams, or broader groups performing the illegal or unethical conduct.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "relatedProject",
                label: "If applicable, to what project does this incident relate?",
                required: true,
                helpText: [
                    "If this conduct relates to a project, please identify the project.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "incidentDate",
                label: "What date/dates did the incident take place and at what location?",
                required: false,
                helpText: [
                    "If known, provide a date or time span when the illegal or unethical conduct happened as well as the location of such conduct.",
                ],
                isMultiline: true,
                multilineRows: "5",
                isColumn: true,
            },
            [{
                    field: "name",
                    label: "Name",
                    required: false,
                    helpText: [],
                    isMultiline: false,
                    multilineRows: "1",
                    isColumn: false,
                    row: "row_1",
                    spacing: 4,
                    optionalLabel: true,
                },
                {
                    field: "email",
                    label: "Email",
                    required: false,
                    helpText: [],
                    isColumn: false,
                    isMultiline: false,
                    multilineRows: "1",
                    row: "row_1",
                    spacing: 3,
                    optionalLabel: true,
                },
            ],
        ],
    },
    {
        name: "East Asia",
        enabled: true,
        openonload: false,
        description: [
            "Arup appreciates you speaking up in relation to making a disclosure about perceived illegal conduct or unethical behavior that you believe may involve Arup and/or its employees or suppliers of goods and services to Arup. Please answer a few short questions about your disclosure. Your answers to these questions will help us direct your disclosure to the appropriate person from our Speak Up team.",
            "Your disclosure will be kept confidential, and any information you disclose here will be kept in a secure file management system. Arup will assess all disclosures made, and will determine what action, including the requirement for an investigation, is required. If you elect to provide your contact details you will be kept informed, where appropriate of any actions that Arup have taken regarding your disclosure.",
        ],
        formElements: [{
                field: "disclosureNature",
                label: "What is the illegal conduct or unethical behavior you are disclosing?",
                required: true,
                helpText: [
                    "Please describe the illegal conduct or unethical behavior you are disclosing.  Types of conduct you may want to disclose include but are not limited to, dishonestly, fraud, corruption, and bribery.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "8",
            },
            {
                field: "howAwareConduct",
                label: "How did you become aware of this conduct?",
                required: true,
                helpText: [
                    "Please describe how you became aware of this conduct, whether you witnessed it firsthand or were informed by someone else.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "disclosurePeople",
                label: "Who is/are the person/people involved in this disclosure?",
                required: true,
                helpText: [
                    "If known, list the person, teams, or broader groups performing the conduct.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "relatedProject",
                label: "If applicable, to what project does this incident relate?",
                required: true,
                helpText: [
                    "If this conduct relates to a project, please identify the project.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "incidentDate",
                label: "What date/dates did the incident take place and at what location?",
                required: false,
                helpText: [
                    "If known, provide a date or time span when the conduct happened as well as the location of such conduct.",
                ],
                isMultiline: true,
                multilineRows: "5",
                isColumn: true,
            },
            [{
                    field: "name",
                    label: "Name",
                    required: false,
                    helpText: [],
                    isMultiline: false,
                    multilineRows: "1",
                    isColumn: false,
                    row: "row_1",
                    spacing: 3,
                    optionalLabel: true,
                },
                {
                    field: "email",
                    label: "Email Address",
                    required: false,
                    helpText: [],
                    isColumn: false,
                    isMultiline: false,
                    multilineRows: "1",
                    row: "row_1",
                    spacing: 4,
                    optionalLabel: true,
                },
            ],
        ],
    },
    {
        name: "Europe",
        enabled: true,
        openonload: true,
        description: [
            "Arup appreciates you speaking up in relation to making a disclosure about perceived illegal conduct or unethical behaviour that you believe may involve Arup and/or its employees or suppliers of goods and services to Arup. Please answer a few short questions about your disclosure. Your answers to these questions will help us direct your disclosure to the appropriate person from our Speak Up team.",
            "Your disclosure will be kept strictly confidential, and any information you disclose online will be kept in a secure file management system. Arup will assess all disclosures made, and will determine what action, including the requirement for an investigation, is required. If you elect to provide your contact details you will be kept informed, where appropriate of any actions that Arup have taken regarding your disclosure.",
        ],
        formElements: [{
                field: "disclosureNature",
                label: "What is the illegal conduct or unethical behaviour you are disclosing?",
                required: true,
                helpText: [
                    "Please describe the illegal conduct or unethical behaviour you are disclosing.Types of conduct you may want to disclose include, but are not limited to, dishonesty, fraud, corruption and bribery. If you would like, a Speak Up team member can contact you to discuss your disclosure.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "8",
            },
            {
                field: "disclosurePeople",
                label: "Who is/are the person/people involved in this disclosure?",
                required: true,
                helpText: [
                    "If known, list the people, teams or broader groups that are doing the illegal conduct or unethical behaviour.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "incidentDate",
                label: "What date/dates did the incident take place?",
                required: false,
                helpText: [
                    'If known, provide a date or time span when the illegal conduct or unethical behaviour happened e.g. "1 January 2020" or from "January to March 2019".',
                ],
                isMultiline: true,
                multilineRows: "5",
                isColumn: true,
            },
            [{
                    field: "name",
                    label: "Name",
                    required: false,
                    helpText: [],
                    isMultiline: false,
                    multilineRows: "1",
                    isColumn: false,
                    row: "row_1",
                    spacing: 4,
                    optionalLabel: true,
                },
                {
                    field: "email",
                    label: "Email",
                    required: false,
                    helpText: [],
                    isColumn: false,
                    isMultiline: false,
                    multilineRows: "1",
                    row: "row_1",
                    spacing: 3,
                    optionalLabel: true,
                },
            ],
        ],
    },
    {
        name: "UKIMEA",
        enabled: true,
        openonload: true,
        description: [
            "Arup appreciates you speaking up in relation to making a disclosure about perceived illegal conduct or unethical behaviour that you believe may involve Arup and/or its employees or suppliers of goods and services to Arup. Please answer a few short questions about your disclosure. Your answers to these questions will help us direct your disclosure to the appropriate person from our Speak Up team.",
            "Your disclosure will be kept strictly confidential, and any information you disclose online will be kept in a secure file management system. Arup will assess all disclosures made, and will determine what action, including the requirement for an investigation, is required. If you elect to provide your contact details you will be kept informed, where appropriate, of any actions that Arup have taken regarding your disclosure.",
        ],
        formElements: [{
                field: "disclosureNature",
                label: "What is the illegal conduct or unethical behaviour you are disclosing?",
                required: true,
                helpText: [
                    "Please describe the illegal conduct or unethical behaviour you are disclosing. Types of conduct you may want to disclose include, but are not limited to, dishonesty, fraud, corruption and bribery. If you would like, a Speak Up team member can contact you to discuss your disclosure.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "8",
            },
            {
                field: "howAwareConduct",
                label: "How did you become aware of this conduct?",
                required: true,
                helpText: [
                    "Please describe how you became aware of this conduct, whether you witnessed it first-hand or were informed by someone else.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "disclosurePeople",
                label: "Who is/are the person/people involved in this disclosure?",
                required: true,
                helpText: [
                    "If known, list the people, teams or broader groups that are participating in illegal conduct or unethical behaviour.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "relatedProject",
                label: "If applicable, to what project does this incident relate?",
                required: true,
                helpText: [
                    "If this conduct relates to a project, please identify the project.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "incidentDate",
                label: "What date/dates did the incident take place and at what location?",
                required: false,
                helpText: [
                    'If known, provide a date or time span when the illegal conduct or unethical behaviour happened e.g. "1 January 2022" or from "January to March 2021" as well as the location of such conduct.',
                ],
                isMultiline: true,
                multilineRows: "5",
                isColumn: true,
            },
            {
                type: "text",
                helpText: [
                    "We encourage you to identify yourself when making a disclosure, as this will make it easier for us to investigate your concerns and obtain additional information where required.",
                ],
            },
            [{
                    field: "name",
                    label: "Name",
                    required: false,
                    helpText: [],
                    isMultiline: false,
                    multilineRows: "1",
                    isColumn: false,
                    row: "row_2",
                    spacing: 4,
                    optionalLabel: true,
                },
                {
                    field: "email",
                    label: "Email",
                    required: false,
                    helpText: ["Hello"],
                    isColumn: false,
                    isMultiline: false,
                    multilineRows: "1",
                    row: "row_2",
                    spacing: 3,
                    optionalLabel: true,
                },
            ],
        ],
    },
    {
        name: "Toot",
        enabled: false,
        openonload: true,
        description: [
            "Arup appreciates you speaking up in relation to making a disclosure about perceived illegal conduct or unethical behaviour that you believe may involve Arup and/or its employees or suppliers of goods and services to Arup. Please answer a few short questions about your disclosure. Your answers to these questions will help us direct your disclosure to the appropriate person from our Speak Up team.",
            "Your disclosure will be kept strictly confidential, and any information you disclose online will be kept in a secure file management system. Arup will assess all disclosures made, and will determine what action, including the requirement for an investigation, is required. If you elect to provide your contact details you will be kept informed, where appropriate of any actions that Arup have taken regarding your disclosure.",
        ],
        formElements: [{
                field: "disclosureNature",
                label: "What is the illegal conduct or unethical behaviour you are disclosing?",
                required: true,
                helpText: [
                    "Please describe the illegal conduct or unethical behaviour you are disclosing.Types of conduct you may want to disclose include, but are not limited to, dishonesty, fraud, corruption and bribery. If you would like, a Speak Up team member can contact you to discuss your disclosure.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "8",
            },
            {
                field: "disclosurePeople",
                label: "Who is/are the person/people involved in this disclosure?",
                required: true,
                helpText: [
                    "If known, list the people, teams or broader groups that are doing the illegal conduct or unethical behaviour.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "incidentDate",
                label: "What date/dates did the incident take place?",
                required: false,
                helpText: [
                    'If known, provide a date or time span when the illegal conduct or unethical behaviour happened e.g. "1 January 2020" or from "January to March 2019".',
                ],
                isMultiline: true,
                multilineRows: "5",
                isColumn: true,
            },
            [{
                    field: "name",
                    label: "Name",
                    required: false,
                    helpText: [],
                    isMultiline: false,
                    multilineRows: "1",
                    isColumn: false,
                    spacing: 4,
                    optionalLabel: true,
                },
                {
                    field: "email",
                    label: "Email",
                    required: false,
                    helpText: [],
                    isColumn: false,
                    isMultiline: true,
                    multilineRows: "1",
                    spacing: 3,
                    optionalLabel: true,
                },
            ],
        ],
    },
    {
        name: "Global",
        enabled: true,
        openonload: true,
        description: [
            "Arup appreciates you speaking up in relation to making a disclosure about perceived illegal conduct or unethical behaviour that you believe may involve Arup and/or its employees or suppliers of goods and services to Arup. Please answer a few short questions about your disclosure. Your answers to these questions will help us direct your disclosure to the appropriate person from our Speak Up team.",
            "Your disclosure will be kept strictly confidential, and any information you disclose online will be kept in a secure file management system. Arup will assess all disclosures made, and will determine what action, including the requirement for an investigation, is required. If you elect to provide your contact details you will be kept informed, where appropriate of any actions that Arup have taken regarding your disclosure.",
        ],
        formElements: [{
                field: "disclosureNature",
                label: "What is the illegal conduct or unethical behavior you are disclosing?",
                required: true,
                helpText: [
                    "Please describe the illegal conduct or unethical behavior you are disclosing.  Types of conduct you may want to disclose include but are not limited to, dishonestly, fraud, corruption, and bribery.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "8",
            },
            {
                field: "howAwareConduct",
                label: "How did you become aware of this conduct?",
                required: true,
                helpText: [
                    "Please describe how you became aware of this conduct, whether you witnessed it firsthand or were informed by someone else.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "disclosurePeople",
                label: "Who is/are the person/people involved in this conduct?",
                required: true,
                helpText: [
                    "If known, list the person, teams, or broader groups performing the conduct.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "relatedProject",
                label: "If applicable, to what project does this incident relate?",
                required: false,
                helpText: [
                    "If this conduct relates to a project, please identify the project.",
                ],
                isColumn: true,
                isMultiline: true,
                multilineRows: "6",
                validationMessage: "",
            },
            {
                field: "incidentDate",
                label: "What date/dates did the incident take place and at what location?",
                required: false,
                helpText: [
                    "If known, provide a date or time span when the conduct happened as well as the location of such conduct.",
                ],
                isMultiline: true,
                multilineRows: "5",
                isColumn: true,
            },
            {
                type: "text",
                helpText: [
                    "We encourage you to identify yourself when making a disclosure, as this will assist us in investigating your concerns and obtain additional information where required.",
                ],
            },
            [{
                    field: "name",
                    label: "Name",
                    required: false,
                    helpText: [],
                    isMultiline: false,
                    multilineRows: "1",
                    isColumn: false,
                    row: "row_1",
                    spacing: 3,
                    optionalLabel: true,
                },
                {
                    field: "email",
                    label: "Email Address",
                    required: false,
                    helpText: [],
                    isColumn: false,
                    isMultiline: false,
                    multilineRows: "1",
                    row: "row_1",
                    spacing: 4,
                    optionalLabel: true,
                },
            ],
        ],
    },
];