import React from 'react';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'

const Submitted = () => {
	return (
    <Container component="main" maxWidth="md" noValidate>
      <Box display="flex" maxWidth="md" mt={2}>
        <Box m="auto">
          <Alert
            severity="success"
            icon={false}
            style={{ textAlign: "center" }}
            align="center"
          >
            <Typography
              align="center"
              color="textPrimary"
              component="h5"
              variant="h6"
              gutterBottom
              data-testid="labelSuccessMsg1"
            >
              Thank you for taking the time to make this disclosure, receipt of which is hereby acknowledged.
            </Typography>
            <Typography data-testid="labelSuccessMsg2" align="center" gutterBottom>
              We will diligently follow up on the information you have provided. If you have included your 
              contact details we will aim to provide feedback to you within a reasonable timeframe, 
              which should not exceed three months.
            </Typography>
          </Alert>
        </Box>
      </Box>
    </Container>
  );
}

export default Submitted;
