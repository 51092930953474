import React, {useState} from "react";
import { useForm, Controller } from "react-hook-form";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
import axios from "axios";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useHistory } from 'react-router-dom';
import { templates } from "../templates.js";

const RenderColumn = (props) => {
  if (props.item.type === "text") {
    return (
      <Grid container spacing={0} className="field-container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            {props.item.helpText}
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={0} className="field-container">
      <Grid item xs={12}>
        <label htmlFor={props.item.field} data-testid={`labelQuestions${props.item.field}`}>{props.item.label} {props.item.required ? "*"  : ""}</label>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <RenderField
            attributes={props.item}
            control={props.control}
            errors={props.errors}
          />
        </Grid>
        {props.item.helpText.length > 0 ? (
          <Grid item xs={12} sm={5}>
            <Box className="help-text help-text-em" data-testid="labelTextDescription">{props.item.helpText}</Box>
            <Grid container spacing={0}></Grid>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

const RenderRow = (props) => {
  const row = []
  for (let [index, e] of props.row.entries()) {
    row.push(
      <Grid item xs={12} sm={e.spacing} key={index}>
        <label htmlFor={e.field} data-testid={`labelOptionalFields${e.field}`}>
          {e.label} {e.optionalLabel ? "(optional)"  : ""}
        </label>

        <RenderField
          attributes={e}
          control={props.control}
          errors={props.errors}
        />
      </Grid>
    );
  }
  return (
    <Grid container className="field-container" spacing={2}>
      {row}
    </Grid>
  );
}

const RenderField = (props) => {
  return (
    <>
      <Controller
        name={props.attributes.field}
        control={props.control}
        defaultValue=""
        rules={{
          required: props.attributes.required,
          validate: (value) => {
            if (props.attributes.required) {
              return !!value.trim();
            } else {
              return true;
            }
          },
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            multiline={props.attributes.isMultiline}
            fullWidth
            variant="outlined"
            rows={props.attributes.multilineRows}
            size="small"
            error={!!props.errors[props.attributes.field]}
            inputRef={ref}
            inputProps={{
              'data-testid':'labelTextAreaField'
            }}
            
          />
        )}
      />
      {props.errors[props.attributes.field]?.type === "required" && (
        <Box className="form-error" data-testid='labelRequiredField'>This field is required</Box>
      )}
      {props.errors[props.attributes.field]?.type === "validate" && (
        <Box className="form-error">This field must contain text</Box>
      )}
    </>
  );
}

export default function RenderForm(props) {
  const formElements = templates.find((x) => x.name === props.country).formElements;
  const formDescription = templates.find((x) => x.name === props.country).description;
  const openConfirm = templates.find((x) => x.name === props.country).openonload;
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [result, setResult] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(openConfirm);
  const history = useHistory(); 
  const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm({ mode: "onBlur" });
    const onSubmit = data => {
      setIsDisabled(true);
      data["FormIdentifier"] = props.country;
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/api/SendSubmission`, data)
        .then(
          (response) => {
            console.log(response);
            if (response.status === 200) {
              history.push("/Submitted");
            } else {
              setResult("Form submission failed. Please try again.");
              setIsDisabled(false);
              setOpen(true);
            }
          },
          (error) => {
            console.log(error);
            setResult("Form submission failed. Please try again.");
            setIsDisabled(false);
            setOpen(true);
          }
        );
      window.scrollTo(0, 0)
    };

    const handleClose = () => {
      setOpen(false);
    };

    const onYesHandleClose = () => {
      setOpenConfirmDialog(false);
    };

    const onNoHandleClose = () => {
      setOpenConfirmDialog(false);
      history.push(`/`);
    };
        
    return (
      <Container
        component="main"
        maxWidth="md"
        noValidate
        className="form-container"
      >
        {/* <CssBaseline /> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">Disclosure Form</Typography>
            </Grid>
            <Grid item xs={12}>
              {formDescription.map((paragraph, index) => (
                <p data-testid={`labelDisclosureStatement${index}`} key={index}>{paragraph}</p>
              ))}
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box className="help-text" data-testid='labelMandatoryFields'>All fields with * are mandatory.</Box>
            </Grid>
          </Grid>

          {formElements.map((field, index) => {
            if (Array.isArray(field)) {
              return (
                <RenderRow
                  row={field}
                  key={index}
                  errors={errors}
                  control={control}
                />
              );
            } else {
              return (
                <RenderColumn
                  item={field}
                  key={index}
                  errors={errors}
                  control={control}
                />
              );
            }
          })}

          <Grid container spacing={2} className="field-container">
            <Grid item xs={12} sm={7}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                disabled={isDisabled}
                classes={{ root: "form-submit" }}
                data-testid='labelSend'
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </form>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Error</DialogTitle>
            <DialogContent>{result}</DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                classes={{ root: "modal-button-primary" }}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog
            open={openConfirmDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Please confirm..</DialogTitle>
            <DialogContent className="dialog-text">
              You have selected {props.country} Region, is this correct?
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onYesHandleClose}
                classes={{ root: "modal-button-primary" }}
              >
                Yes
              </Button>
              <Button
                onClick={onNoHandleClose}
                classes={{ root: "modal-button-secondary" }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    );
}