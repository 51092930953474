import Container from '@material-ui/core/Container';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { templates } from '../templates.js';

const useStyles = makeStyles((theme) => ({
    heroContent: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(5, 0, 5),
    },
    formControl: {
        margin: theme.spacing(4, 10),
        minWidth: 397,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

const EnabledForms = templates.filter((form) => form.enabled);

export default function Home() {
    const classes = useStyles();
    const [region] = React.useState('');
    const history = useHistory();
    const handleChange = (event) => {
        history.push(`/${event.target.value}`);
    }
    return (
        <main>
            <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        Please select your Region.
                    </Typography>
                </Container>
                <Container maxWidth="sm">
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-helper-label">Region</InputLabel>
                        <Select
                            data-testid="labelSelectRegion"
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={region}
                            onChange={handleChange}
                        >
                            {
                                EnabledForms.map((r, index) => {
                                    return <MenuItem key={index} value={r.name}>{r.name}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Container>
            </div>
        </main>
    );
}
