import React from 'react';
import NavBar from './components/NavBar';
// import Form from './components/Form';
import Home from './components/Home'
//import GenericForm from './components/GenericForm'
import RenderForm from "./components/RenderForm";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { templates } from "./templates.js";
import Submitted from './components/Submitted'

import "./App.css";

const App = () => {
    
    return (
        <div className="page-content">
            <NavBar />
            <Router>
                <Switch>
                    <Route exact path="/"><Home /></Route>
                    <Route exact path="/Submitted"><Submitted /></Route>
                    {/*<Route exact path="/Form"><Form /></Route>*/}
                    {
                        templates.map((r, index) => {
                            return (
                              <Route key={index} exact path={`/${r.name}`}>
                                <RenderForm country={r.name} />
                              </Route>
                            );
                        })
                    }
                </Switch>
            </Router>
        </div>
    );
}

export default App;
    